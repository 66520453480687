import classNames from 'classnames';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Check, Icon as FeatherIcon } from 'react-feather';

import { FadeIn } from '../UI/FadeIn';
import { Icon } from '../UI/Icon';
import style from './Notification.module.css';

interface Notification {
  id?: number;
  notification: ReactNode;
  duration?: number;
  icon?: FeatherIcon;
}

interface NotifierValue {
  notifications: (Notification | null)[];
  addNotification: (notification: Notification) => void;
}

export const NotifierContext = createContext<NotifierValue>({
  notifications: [],
  addNotification: () => null,
});

export function useNotifier() {
  const { addNotification } = useContext(NotifierContext);

  const notify = (notification: ReactNode, icon = Check, duration?: number) => {
    addNotification({ notification, icon, duration });
  };

  return {
    notify,
  };
}

export default function NotificationProvider({
  children,
  duration = 5000,
}: {
  duration?: number;
  children?: ReactNode;
}) {
  const idRef = useRef<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const timerRef = useRef<number[]>([]);

  /** Add a notification to the list */
  const addNotification = (notification: Notification) => {
    const id = idRef.current;
    setNotifications((notifications) => [{ ...notification, id, a: 3 }, ...notifications]);
    idRef.current ++;

    const timer = window.setTimeout(() => popNotification(), notification.duration || duration);

    timerRef.current.push(timer);
  };

  /** Removes the oldest notification from the list */
  const popNotification = () => {
    setNotifications((notifications) => notifications.slice(0, notifications.length - 1));
  };

  useEffect(() => {
    const timers = timerRef.current;

    return () => {
      timers.forEach(window.clearTimeout);
    };
  }, []);

  return (
    <NotifierContext.Provider value={{ notifications, addNotification }}>
      <div
        className={classNames(
          'fixed left-0 bottom-0 p-8 max-h-screen overflow-hidden z-50',
        )}
      >
        <div className="flex flex-col-reverse items-start">
          {notifications.map((notification, index) => (
            <div
              className={classNames(
                'flex items-center h-8 rounded-sm bg-emerald-600 text-white text-sm font-medium mt-2 px-3 overflow-hidden transition-opacity',
                // Fade out older notifications a little bit
                index > 4 && 'opacity-0',
                index === 4 && 'opacity-20',
                index === 3 && 'opacity-40',
                index === 2 && 'opacity-60',
                index === 1 && 'opacity-80',
                style.notification,
              )}
              key={notification.id}
            >
              <FadeIn>
                {notification.icon && (
                  <Icon className="mr-2">
                    <notification.icon />
                  </Icon>
                )}
                {notification.notification}
              </FadeIn>
            </div>
          ))}
        </div>
      </div>
      {children}
    </NotifierContext.Provider>
  );
}
