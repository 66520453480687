import classNames from 'classnames';
import { DetailedHTMLProps, LabelHTMLAttributes } from 'react';

interface InputLabelProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  required?: boolean;
  valid?: boolean;
}

export default function InputLabel({
  required,
  valid,
  className,
  children,
  ...props
}: InputLabelProps) {
  return (
    <label className={classNames('block font-bold mb-2', className)} {...props}>
      {children}
      {required && <span className={classNames('ml-1 !font-light', valid ? 'text-slate-400' : 'text-red-600')}>*</span>}
    </label>
  );
}
