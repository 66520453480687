import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputSize?: 'sm' | 'md' | 'lg';
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ inputSize = 'md', ...props }, ref) => {
    const className = classNames(
      'block w-full text-md placeholder:text-slate-400 rounded-md border border-slate-250 shadow-2xs focus:ring-1 focus:ring-blue-600 focus:outline-none focus:border-blue-600 px-3',
      inputSize === 'sm' && props.type !== 'file' && 'h-8',
      inputSize === 'md' && props.type !== 'file' && 'h-10',
      inputSize === 'lg' && props.type !== 'file' && 'h-12',
      !props.disabled && 'text-slate-800',
      props.disabled && 'bg-slate-50 text-slate-500',
      props.type === 'file' && 'px-1 py-1',
      props.className,
    );

    return (
      <input {...props} ref={ref} className={className} />
    );
  }
);

Input.displayName = 'Input';
