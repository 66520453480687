import { Errors, Page, PageProps, RequestPayload, router, VisitOptions } from '@inertiajs/core';
import { usePage } from '@inertiajs/react';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DeepPartial, FieldPath, FieldValues, UseFormReturn, UseFormSetError } from 'react-hook-form';

import { Locale } from './types';
import useMemoizedValue from './useMemoizedValue';

export function useData<T = unknown>(
  path: string | null,
  options?: AxiosRequestConfig,
  { debounce }: { debounce?: number | null; } = {},
) {
  const { locale } = usePage<PageProps & { locale: Locale; }>().props;
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const memoizedOptions = useMemoizedValue({
    paramsSerializer: (params: unknown) => qs.stringify(params),
    ...options,
  });

  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchData = useCallback(() => {
    if (!path) {
      return;
    }

    setLoading(true);

    return axios.get<T>(
      path,
      { ...memoizedOptions, data: { ...memoizedOptions?.data, locale } }
    ).then((response) => {
      if (mountedRef.current) {
        setData(response.data);
        setLoading(false);
      }
    });
  }, [path, memoizedOptions, locale]);

  useEffect(() => {
    if (path) {
      if (typeof debounce === 'number') {
        const timer = window.setTimeout(() => {
          fetchData();
        }, debounce);

        return () => window.clearTimeout(timer);
      }

      fetchData();
    }
  }, [path, fetchData, debounce]);

  return [data, { loading, refetch: fetchData }] as const;
}

export function useMutation<T = Page>(
  path: string,
  options?: Omit<Omit<VisitOptions, 'method'>, 'onSuccess'> & {
    onSuccess?: (page: T) => void;
  },
) {
  const { locale } = usePage<PageProps & { locale: Locale; }>().props;
  const [result, setResult] = useState<T | null>(null);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);

  const post = (data?: RequestPayload) => new Promise(
    (resolve) => router.post(
      path,
      // Add the current locale to the payload to make sure we get localized error messages.
      { locale, ...options?.data, ...data },
      {
        ...options,
        onStart: (visit) => {
          setLoading(true);
          options?.onStart?.(visit);
        },
        onSuccess: (result) => {
          setResult(result as T);
          setErrors(null);
          setSubmitCount((count) => count + 1);
          options?.onSuccess?.(result as T);
        },
        onError: (errors) => {
          setResult(null);
          setErrors(errors);
          options?.onError?.(errors);
        },
        onFinish: (visit) => {
          setLoading(false);
          resolve(visit);
          options?.onFinish?.(visit);
        },
      },
    )
  );

  const formKey = `form_${submitCount}`;
  const rest = { data: result, errors, loading, formKey };

  return [post, rest] as [typeof post, typeof rest];
}

export function setServerErrors<T extends FieldValues = FieldValues>(
  errors: Errors,
  setClientError: UseFormSetError<T>,
  defaultValues?: Readonly<DeepPartial<T>>,
) {
  Object.keys(errors).forEach((key) => {
    // Set server errors that do not correspond with a form field with 'root' as key.
    // This type of error will not persist with each submission.
    const name = (defaultValues && key in defaultValues ? key : `root.${key}`) as FieldPath<T>;

    setClientError(name, { message: errors[key] });
  });
}

export function useServerErrors<T extends FieldValues = FieldValues>(
  form: UseFormReturn<T>,
  errors?: Errors | null,
) {
  const setError = form.setError;
  const defaultValues = form.formState.defaultValues;

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        // Set server errors that do not correspond with a form field with 'root' as key.
        // This type of error will not persist with each submission.
        const name = (defaultValues && key in defaultValues ? key : `root.${key}`) as FieldPath<T>;

        setError(name, { message: errors[key] });
      });
    }
  }, [errors, setError, defaultValues]);
}

export const apiRoutes = {
  auto_complete_address: '/api/auto_complete_address',
};
