import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode, useEffect, useState } from 'react';

import style from './FadeIn.module.css';

interface FadeInProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  when?: boolean;
  delay?: boolean;
  render?: () => ReactNode;
}

export const FadeIn = forwardRef<HTMLDivElement, FadeInProps>(
  ({
    when,
    delay = true,
    render,
    className,
    children,
    ...props
  }: FadeInProps, ref) => {
    // Don't fade in the component on mount when the render prop is used. This makes sure that on
    // the initial page load, the component doesn't fade in, but simply renders instantly.
    const [shouldAnimate, setShouldAnimate] = useState(typeof render === 'undefined');

    // The component should only animate if it has been invisible before.
    useEffect(() => {
      if (!when) {
        setShouldAnimate(true);
      }
    }, [when]);

    if (render && when === false) {
      return <></>;
    }

    return (
      <div
        className={classNames(shouldAnimate && (delay ? style.fadeInDelayed : style.fadeIn), className)}
        ref={ref}
        {...props}
      >
        {when && render?.()}
        {!render && children}
      </div>
    );
  }
);

FadeIn.displayName = 'FadeIn';
