import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import { ComponentPropsWithoutRef, ElementType } from 'react';

export interface CardProps<E extends ElementType> {
  as?: E;
  spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  panels?: boolean;
  header?: boolean;
  footer?: boolean;
  shadow?: boolean;
  border?: boolean;
  active?: boolean;
  link?: {
    href?: string;
    onClick?: () => void;
    ariaLabel?: string;
    reload?: boolean;
    blank?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  };
}

export function Card<E extends ElementType = 'div'>({
  as,
  spacing = 'md',
  panels = false,
  header = false,
  footer = false,
  shadow = true,
  border = false,
  active = false,
  link,
  children,
  ...props
}: CardProps<E> & Omit<ComponentPropsWithoutRef<E>, keyof CardProps<E>>) {
  const className = classNames(
    // Base
    'bg-white rounded-xl relative transition-shadow',
    // Default padding
    !panels && spacing === 'xs' && 'p-2 sm:p-4',
    !panels && spacing === 'sm' && 'p-4 sm:p-6',
    !panels && spacing === 'md' && 'p-6 sm:p-8',
    !panels && spacing === 'lg' && 'p-8 sm:p-10',
    !panels && spacing === 'xl' && 'p-10 sm:p-12',
    // Panels
    panels && '[&>*+*]:border-t [&>*:last-child]:rounded-b-xl [&>*:first]:rounded-t-xl',
    panels && spacing === 'xs' && '[&>*]:p-2 sm:[&>*]:p-4',
    panels && spacing === 'sm' && '[&>*]:p-4 sm:[&>*]:p-6',
    panels && spacing === 'md' && '[&>*]:p-6 sm:[&>*]:p-8',
    panels && spacing === 'lg' && '[&>*]:p-8 sm:[&>*]:p-10',
    panels && spacing === 'xl' && '[&>*]:p-10 sm:[&>*]:p-12',
    // Header
    header && '[&>*:first-child]:bg-gradient-to-t [&>*:first-child]:from-slate-50/70 [&>*:first-child]:border-bl',
    header && spacing === 'xs' && '[&>*:first-child]:py-2',
    header && spacing === 'sm' && '[&>*:first-child]:py-3',
    header && spacing === 'md' && '[&>*:first-child]:py-4',
    header && spacing === 'lg' && '[&>*:first-child]:py-5',
    header && spacing === 'xl' && '[&>*:first-child]:py-6',
    // Footer
    footer && '[&>*:last-child]:bg-slate-50',
    footer && spacing === 'xs' && '[&>*:last-child]:py-2',
    footer && spacing === 'sm' && '[&>*:last-child]:py-3',
    footer && spacing === 'md' && '[&>*:last-child]:py-4',
    footer && spacing === 'lg' && '[&>*:last-child]:py-5',
    footer && spacing === 'xl' && '[&>*:last-child]:py-6',
    // Shadow
    shadow && !active && border && 'shadow-xs',
    shadow && !active && !border && 'shadow-sm',
    shadow && active && 'shadow-lg',
    link && shadow && 'cursor-pointer hover:shadow-md',
    // Border
    border && 'border',
    props.className,
  );

  const Component = as || 'div';

  return (
    <Component {...props} className={className}>
      {link?.href && !link.reload && !link.blank && (
        <Link
          href={link.href}
          onClick={link.onClick}
          aria-label={link.ariaLabel}
          className="absolute inset-0 z-10"
          onMouseEnter={link.onMouseEnter}
          onMouseLeave={link.onMouseLeave}
        />
      )}
      {link?.href && (link.reload || link.blank) && (
        /* eslint-disable react/jsx-no-target-blank */
        <a
          href={link.href}
          onClick={link.onClick}
          aria-label={link.ariaLabel}
          className="absolute inset-0 z-10"
          target={link.blank ? '_blank' : undefined}
          rel={link.blank ? 'noopener noreferer' : undefined}
          onMouseEnter={link.onMouseEnter}
          onMouseLeave={link.onMouseLeave}
        />
      /* eslint-enable */
      )}
      {link?.onClick && !link.href && (
        <a
          onClick={link.onClick}
          aria-label={link.ariaLabel}
          className="absolute inset-0 z-10"
          role="button"
          onMouseEnter={link.onMouseEnter}
          onMouseLeave={link.onMouseLeave}
        />
      )}
      {children}
    </Component>
  );
}
