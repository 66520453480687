import classNames from 'classnames';
import { forwardRef, useEffect, useState } from 'react';

import { AButton as BaseAButton, Button as BaseButton, LinkButton as BaseLinkButton } from '../../Shared/UI/Button';

type ButtonProps = Parameters<typeof BaseButton>[0];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }: ButtonProps, ref) => {
  const isPrimary = !props.variant || props.variant === 'primary';
  const className = useCharityClassNames(props.className, isPrimary);

  return (
    <BaseButton variant="custom" {...props} className={className} ref={ref}>
      <span className={classNames(isPrimary && 'drop-shadow-project-comp')}>
        {children}
      </span>
    </BaseButton>
  );
});

Button.displayName = 'Button';

type LinkButtonProps = Parameters<typeof BaseLinkButton>[0];

export function LinkButton({ children, ...props }: LinkButtonProps) {
  const isPrimary = !props.variant || props.variant === 'primary';
  const className = useCharityClassNames(props.className, isPrimary);

  return (
    <BaseLinkButton variant="custom" {...props} className={className}>
      <span className={classNames(isPrimary && 'drop-shadow-project-comp')}>
        {children}
      </span>
    </BaseLinkButton>
  );
}
type AButtonProps = Parameters<typeof BaseAButton>[0];

export function AButton({ children, ...props }: AButtonProps) {
  const isPrimary = !props.variant || props.variant === 'primary';
  const className = useCharityClassNames(props.className, isPrimary);

  return (
    <BaseAButton variant="custom" {...props} className={className}>
      <span className={classNames(isPrimary && 'drop-shadow-project-comp')}>
        {children}
      </span>
    </BaseAButton>
  );
}

const useCharityClassNames = (initialClassName?: string, isPrimary?: boolean) => {
  const [className, setClassName] = useState(classNames(
    initialClassName,
    isPrimary && 'bg-project border border-project text-project-comp hover:bg-project-hover hover:border-project-hover hover:text-project-comp focus:ring-project/30 !transition-none',
  ));

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setClassName((className) => className.replace(/ !transition-none$/, ''));
    }, 100);

    return () => window.clearTimeout(timer);
  }, []);

  return className;
};
